import { ClockIcon, PinFillIcon } from "@lifesg/react-icons";
import Table, { ITableHeader, ITableProps } from "app/components/basic/Table";
import Blockquote from "app/components/composites/Blockquote";
import TextWithIcon from "app/components/composites/TextWithIcon";
import { ITimelineItemProps, TimelineItem } from "app/components/composites/TimelineList";
import {
	GuidesBulletList,
	GuidesEmail,
	GuidesExternalLink,
	GuidesImages,
	GuidesInternalLink,
} from "app/components/templates/Guide/__common__";
import DownloadButton from "app/components/templates/DeathCert/DownloadButton";
import axios from "axios";
import parse from "html-react-parser";
import { CSSProperties } from "react";
import ReactDOMServer from "react-dom/server";
import { SemanticWIDTHS } from "semantic-ui-react/dist/commonjs/generic";
import { lowercaseAndHyphenSpecialChar } from "../strings";
export interface ITranslatedObject {
	key: string;
	translation: string;
	element: string;
	id?: string;
}
interface IProps {
	translatedObjects: ITranslatedObject[];
}

enum Elements {
	h1 = "h1",
	h3 = "h3",
	h4 = "h4",
	h5 = "h5",
	h6 = "h6",
	p = "p",
	ul = "ul",
	ol = "ol",
	Blockquote = "Blockquote",
	TableHeader = "Table-header",
	TableRow = "Table-row",
	Img = "img",
	Youtube = "youtube",
	DownloadButton = "download-button",
	Location = "location",
	Time = "time",
	TimeLineList = "time-line-list",
}

export const parseURL = (urlStr: string): { name: string; href: string } => {
	const strings = urlStr.split(/\[{2}|\||\]{2}/); // extract name and href from [[name|href]] identifier
	return {
		name: strings[1],
		href: strings[2],
	};
};

export const replaceInlineLinks = (text: string, key: string): string => {
	// check for [[name|href]] identifier
	// allowed 'name' characters: any character except newline
	// allowed 'href' characters: alphanumeric, "/"", ":", ".", "-", "_"
	const linkRegex = /\[{2}.*?\|[A-Z0-9()/:.\-_#%?=@&;]*]{2}/gi;
	const links = text.match(linkRegex);

	if (links) {
		links.forEach((link: string, index: number) => {
			const { name, href } = parseURL(link);
			let linkHtmlString;
			if (href.includes("mailto:")) {
				linkHtmlString = ReactDOMServer.renderToString(GuidesEmail(href.split(":")[1]!));
			} else if (href.startsWith("tel:")) {
				linkHtmlString = ReactDOMServer.renderToString(GuidesInternalLink(name, href, `link_phone_${name}`));
			} else {
				linkHtmlString = ReactDOMServer.renderToString(
					href.charAt(0) === "/"
						? GuidesInternalLink(name, href, `${key}-${index}`)
						: GuidesExternalLink(name, href, true, `${key}-${index}`),
				);
			}

			text = text.replace(link, linkHtmlString);
		});
	}
	return text;
};

export const splitListByIdentifier = (list: string, identifier: string): string[] => {
	const items: string[] = list.split(identifier);
	if (items[0] === "") {
		items.shift();
	}

	return items;
};

/**
 * Converts given sting for style to CSSProperties(Object)
 * @param {string} style [e.g. 'width: 100%; height: auto; margin: 24px 0 0;']
 */
export const getStyleObject = (style: string): CSSProperties | undefined => {
	if (!style) {
		return;
	}
	return style.split(";").reduce((prev: {}, current: string) => {
		const style = current.split(":").map((item: string) => item.trim());
		if (style[0] && style[1]) {
			prev[style[0]] = style[1];
		}
		return prev;
	}, {});
};

export const getTableElement = (translatedObjects: ITranslatedObject[], className?: string): JSX.Element => {
	const tableProps: ITableProps = {
		key: "",
		id: "",
		tableWidth: "eight",
		headerText: [],
		cellRowText: [],
	};
	if (className) {
		tableProps.className = className;
	}
	translatedObjects.forEach((item: ITranslatedObject) => {
		if (item.element === Elements.TableHeader) {
			const headers: string[] = splitListByIdentifier(item.translation, "*");
			/* generate the table id */
			tableProps.key = tableProps.id = headers.shift()!.split("=")[1];
			const headerTextArr: ITableHeader[] = [];
			headers.forEach((header: string) => {
				const headerProps = splitListByIdentifier(header, "||");
				const tableHeader: ITableHeader = {
					text: headerProps[0].trim(),
					columnWidth: headerProps[1].trim() as SemanticWIDTHS,
					hideColumnHeaderOnMobile: headerProps.length > 2 && headerProps[2].trim() === "true",
				};
				headerTextArr.push(tableHeader);
			});
			tableProps.headerText = headerTextArr;
		} else {
			const cellRowArr: string[] = splitListByIdentifier(item.translation, "*");
			// allow html within cells.
			const cellRowAsElements: JSX.Element[] = cellRowArr.map((cellRow: string, index: number) => (
				<>{parse(replaceInlineLinks(cellRow, "link"))}</>
			));
			tableProps.cellRowText.push(cellRowAsElements);
		}
	});
	return <Table {...tableProps} />;
};

const renderTimeLineItems = (items) => {
	return (
		<div style={{ marginTop: 35 }}>
			{items.map((item: ITimelineItemProps, index: number) => {
				return <TimelineItem content={item.content} key={`timeline-item${index}`} />;
			})}
		</div>
	);
};

export const renderElement = (obj: ITranslatedObject): JSX.Element => {
	let Element: any = "p";
	const textWithInlineLink = replaceInlineLinks(obj.translation, obj.key);

	switch (obj.element) {
		case Elements.h1:
		case Elements.h3:
		case Elements.h4:
		case Elements.h5:
		case Elements.h6:
			Element = obj.element;
			return (
				<Element key={obj.key} id={obj.id ? lowercaseAndHyphenSpecialChar(obj.id) : ""}>
					{parse(textWithInlineLink)}
				</Element>
			);
		case Elements.p:
			Element = obj.element;
			return <Element key={obj.key}>{parse(textWithInlineLink)}</Element>;

		case Elements.ul:
		case Elements.ol: {
			const listItems = splitListByIdentifier(obj.translation, "*");
			const listItemsWithInlineLink: string[] = [];
			listItems.forEach((item: string, index: number) => {
				const itemWithInlineLink = replaceInlineLinks(item, `${obj.key}-${index}`);
				listItemsWithInlineLink.push(itemWithInlineLink);
			});
			return GuidesBulletList(obj.element, listItemsWithInlineLink);
		}

		case Elements.Blockquote:
			return (
				<Blockquote className="mt24" key={obj.key}>
					{parse(textWithInlineLink)}
				</Blockquote>
			);

		case Elements.Img: {
			const images = splitListByIdentifier(obj.translation, "*").map((item: string) => {
				const data = item.split("|").map((item: string) => new URLSearchParams(item));
				return {
					src: data[0].get("src")!,
					alt: data[1] ? data[1].get("alt")! : undefined,
					style: data[2] ? getStyleObject(data[2].get("style")!) : undefined,
				};
			});
			return GuidesImages(images);
		}

		case Elements.Youtube:
			return (
				<div className="youtube-container">
					<iframe
						src={textWithInlineLink}
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					/>
				</div>
			);

		case Elements.Location:
			return <TextWithIcon icon={<PinFillIcon />}>{parse(textWithInlineLink)}</TextWithIcon>;

		case Elements.TimeLineList: {
			const timeLineItems = splitListByIdentifier(obj.translation, "*");
			const timeLineItemsWithInlineLink: object[] = [];
			timeLineItems.forEach((timeLineItem: string, index: number) => {
				const itemWithInlineLink = { content: parse(replaceInlineLinks(timeLineItem, "index")) };
				timeLineItemsWithInlineLink.push(itemWithInlineLink);
			});
			return renderTimeLineItems(timeLineItemsWithInlineLink);
		}

		case Elements.DownloadButton:
			return (
				<DownloadButton
					fileName={parseURL(obj.translation).name}
					callback={async () => {
						return await axios.get(parseURL(obj.translation).href, { responseType: "blob" });
					}}
				/>
			);

		case Elements.Time:
			return <TextWithIcon icon={<ClockIcon />}>{parse(textWithInlineLink)}</TextWithIcon>;

		default:
			return <Element key={obj.key}>{parse(textWithInlineLink)}</Element>;
	}
};

export const TranslationPageRenderer = (props: IProps): JSX.Element => {
	const jsxArr: JSX.Element[] = [];
	if (!props.translatedObjects || !props.translatedObjects.length) {
		return <></>;
	}
	let tableElementsObjects: ITranslatedObject[] = [];
	props.translatedObjects.forEach((obj: ITranslatedObject, index: number) => {
		if (obj.element === Elements.TableHeader || obj.element === Elements.TableRow) {
			tableElementsObjects.push(obj);

			const nextElement =
				index < props.translatedObjects.length - 1 ? props.translatedObjects[index + 1].element : undefined;
			// end of table
			if (nextElement !== Elements.TableRow) {
				// Adding margin bottom to current table to have more spacing between contiguous tables
				const className = nextElement === Elements.TableHeader ? "mb40" : "";
				jsxArr.push(getTableElement(tableElementsObjects, className));
				tableElementsObjects = [];
			}
		} else {
			const element = renderElement(obj);
			jsxArr.push(element);
		}
	});
	return <>{jsxArr}</>;
};

/**
 * Returns a list of JSX.Elements
 * Currently used in FAQ to parse JSX.Elements into template
 * @param props
 */
export const ElementRenderer = (props: ITranslatedObject[]): JSX.Element[] => {
	const jsxArr: JSX.Element[] = [];

	props.forEach((obj: ITranslatedObject) => {
		jsxArr.push(renderElement(obj));
	});

	return jsxArr;
};
