import * as React from "react";
import "./Pill.scss";

export enum PillType {
	info = "info", // blue
	error = "error", // red
	warning = "warning", // orange
	success = "success", // green
	neutral = "neutral", // grey
	text = "text", //plain text
	draft = "draft", // italic text
}

interface IProps {
	text: string;
	type: PillType;
	isTag?: boolean;
}

const Pill = (props: IProps): JSX.Element => {
	const { text, type, isTag } = props;
	return (
		<div className={`pill-message ${type} ${isTag ? "tag" : ""}`}>
			<span className="pill-message__body">{text}</span>
		</div>
	);
};

export default Pill;
