import * as React from "react";
import moment from "moment";
import Card from "app/components/basic/Card";
import BasicLink from "app/components/basic/Link";
import "./EventListingCard.scss";
import { CalendarIcon, ClockIcon, PinFillIcon } from "@lifesg/react-icons";

export interface IEventListingCardProps {
	eventTitle: string;
	eventDescription: string;
	language: string;
	startTime: moment.Moment;
	endTime: moment.Moment;
	location: string;
	bookingPageURL: string;
	isFullyBooked: boolean;
	isButtonOnRightSide?: boolean;
	index?: number;
}

/**
 * Event listing card to show event details and register button
 *
 * Note: end date will not be rendered even it's a different date from the start date
 * @param props
 * @constructor
 */
const EventListingCard = (props: IEventListingCardProps): JSX.Element => {
	const formattedDate = props.startTime.format("ddd, D MMM YYYY");

	const formatTime = (time: moment.Moment): string => {
		const timeFormat = time.minutes() ? "h:mma" : "ha";
		return time.format(timeFormat);
	};

	let className = "event-listing-card";
	if (props.isButtonOnRightSide) {
		className += " event-listing-card--wide";
	}

	return (
		<Card isStatic className={className}>
			<div data-testid="event-listing-card__content" className="event-listing-card__content">
				<h5 className="event-listing-card__title">{props.eventTitle}</h5>
				<p className="event-listing-card__description">{props.eventDescription}</p>
				<p className="event-listing-card__language">{props.language}</p>
				<div className="event-listing-card__details">
					<div className="event-listing-card__details__icon">
						<CalendarIcon />
					</div>
					{formattedDate}
				</div>
				<div className="event-listing-card__details">
					<div className="event-listing-card__details__icon">
						<ClockIcon />
					</div>
					{`${formatTime(props.startTime)} - ${formatTime(props.endTime)}`}
				</div>
				{/* Hide location icon if no location info but keep the margin */}
				<div className="event-listing-card__details--location event-listing-card__details">
					{props.location && (
						<div className="event-listing-card__details__icon">
							<PinFillIcon />
						</div>
					)}
					{props.location}
				</div>
				<BasicLink
					className="event-listing-card__register-button"
					id={`EventListingCardButton_${props.index ?? 0}`}
					name={props.isFullyBooked ? "Fully Booked" : "Register"}
					href={props.bookingPageURL}
					openInNewPage={true}
					type={props.isFullyBooked ? "button-disabled" : "button-secondary"}
				/>
			</div>
		</Card>
	);
};

export default EventListingCard;
