import { Transform } from "class-transformer";
import { IsBoolean, IsNumber, IsOptional, IsString } from "class-validator";
import { HTTP_VERBS } from "../../enums";
import { toNumber } from "../../transformers";
import { EventDetailsDO } from "../domainObjects";

export const httpVerb = HTTP_VERBS.GET;
export const url = "/api/events";

export class Query {
	@IsString()
	public startDate!: string;

	@IsString()
	public endDate!: string;

	@IsString()
	@IsOptional()
	public label?: string;

	@IsBoolean()
	@IsOptional()
	public availableOnly?: boolean;

	@IsNumber()
	@IsOptional()
	@Transform(toNumber)
	public size?: number;
}

export class Response extends Array<EventDetailsDO> {}
