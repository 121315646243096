import BasicLink from "app/components/basic/Link";
import ExternalLinkIcon from "app/components/widgets/ExternalLinkIcon";
import { lowercaseAndHyphen } from "app/utils/strings";

// @TODO: make linkId required after all guides are updated
export const GuidesInternalLink = (linkName: string, linkPath: string, linkId?: string): JSX.Element => {
	return (
		<BasicLink
			type="inline-link"
			href={linkPath}
			name={linkName}
			className="guides-internal-link__button"
			id={`guides-internal-link__${linkId ? linkId : lowercaseAndHyphen(linkName)}`}
		/>
	);
};

// @TODO: make linkId required after all guides are updated
export const GuidesExternalLink = (
	linkName: string,
	linkPath: string,
	openInNewPage = true,
	linkId?: string,
): JSX.Element => {
	return (
		<BasicLink
			type="inline-link"
			IconRight={ExternalLinkIcon}
			openInNewPage={openInNewPage}
			href={linkPath}
			name={linkName}
			className="guides-external-link__button"
			id={`guides-external-link__${linkId ? linkId : lowercaseAndHyphen(linkName)}`}
		/>
	);
};
