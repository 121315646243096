import Button from "app/components/basic/Button";
import { ReactNode } from "react";

interface ICardWithActionProps {
	width?: 75 | 100;
	disabled?: boolean;
	action: [string, () => void];
	actionId: string;
	className?: string;
	children: ReactNode;
}

const WithAction = (props: ICardWithActionProps): JSX.Element => {
	let className = "card--with-action";
	if (props.width) {
		className = className + ` card--${props.width}`;
	}

	if (props.className) {
		className = className + ` ${props.className}`;
	}

	return (
		<div className={className}>
			<div className="card--with-action__top">{props.children}</div>
			<div>
				<Button
					id={props.actionId}
					type="danger-borderless"
					name={props.action[0]}
					onClick={props.action[1]}
					disabled={props.disabled}
				/>
			</div>
		</div>
	);
};

export default WithAction;
