import { ReactNode } from "react";
import "./styles.scss";

interface TextWithIconProps {
	icon: JSX.Element;
	children: ReactNode;
}

const TextWithIcon = (props: TextWithIconProps): JSX.Element => {
	return (
		<div className="text-icon-container">
			<div className="text-icon-container__icon">{props.icon}</div>
			<div className="text-icon-container__text">{props.children}</div>
		</div>
	);
};

export default TextWithIcon;
