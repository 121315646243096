/* eslint-disable @typescript-eslint/no-floating-promises */
import EventDetailsAPI from "app/api/eventListing";
import translations from "app/assets/files/key-value-translations/homepage.json";
import BasicLink from "app/components/basic/Link";
import EventListingCard, { IEventListingCardProps } from "app/components/composites/EventListingCard/EventListingCard";
import EventListingPlaceholderCard from "app/components/composites/EventListingPlaceholderCard";
import NoEventCard from "app/components/composites/NoEventCard";
import Segment from "app/components/page/Segment";
import { IProps } from "app/modules/common/type";
import { parseURL } from "app/utils/internationalization/TranslationPageRenderer";
import { useEffect, useState } from "react";
import "./WorkshopsListing.scss";

const renderCards = (props: IProps, workshops: IEventListingCardProps[], isApiError: boolean): JSX.Element => {
	if (workshops.length >= 2) {
		return (
			<>
				{workshops.map((item, index) => (
					<EventListingCard key={index} index={index} {...item} />
				))}
				<div className="index__upcoming-workshops-v2__cards-container__placeholder" />
			</>
		);
	}

	if (workshops.length === 1) {
		return (
			<>
				<EventListingPlaceholderCard {...props} />
				<EventListingCard {...workshops[0]} />
				<div className="index__upcoming-workshops-v2__cards-container__placeholder" />
			</>
		);
	}

	return <NoEventCard isApiError={isApiError} {...props} />;
};

export const WorkshopsListing = (props: IProps): JSX.Element => {
	const [workshops, setWorkshops] = useState<IEventListingCardProps[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const { name } = parseURL(translations[props.language].homepageUpcomingWorkshopsCTA.data);
	const [isApiError, setIsApiError] = useState<boolean>(false);

	let cardContainerClassName = "index__upcoming-workshops-v2__cards-container";
	if (workshops.length === 0) {
		cardContainerClassName = "index__upcoming-workshops-v2__no-workshop-card-container";
	} else if (workshops.length === 1) {
		cardContainerClassName += "--single-card";
	}

	useEffect(() => {
		(async () => {
			try {
				const workshops = await EventDetailsAPI.getEvents({ size: 3 });
				setWorkshops(workshops);
			} catch (error) {
				// Do nothing
				setIsApiError(true);
			} finally {
				setIsLoading(false);
			}
		})();
	}, []);

	if (isLoading) {
		return <></>;
	}

	if (!workshops.length) {
		return <></>;
	}

	return (
		<Segment id="upcoming-workshops-v2" paddingBottom={48}>
			<div className="index__upcoming-workshops-v2">
				<h4 className="index__intro-title semi-bold mb0">
					{translations[props.language].homepageUpcomingWorkshops.data}
				</h4>
				<div className={"index__upcoming-workshops-v2__view-all-workshops"}>
					{workshops.length > 0 ? (
						<BasicLink
							id="workshops-button"
							href="find-a-service/upcoming-workshops"
							name={name}
							disablePrefetch={true}
							fullWidthOnMobile={true}
						/>
					) : (
						<></>
					)}
				</div>
				<div className={cardContainerClassName}>{renderCards(props, workshops, isApiError)}</div>
			</div>
		</Segment>
	);
};

export default WorkshopsListing;
