import { ChevronRightIcon } from "@lifesg/react-icons/chevron-right";
import "./Variation.scss";

interface ICardVariationProps {
	cardTitle: string;
	href?: string;
	clickHandler?: () => void;
	children?: React.ReactNode;
	className?: string;
	id?: string;
}

const CardVariation: React.FunctionComponent<ICardVariationProps> = (props: ICardVariationProps): JSX.Element => {
	const Element = props.href ? "a" : "div";
	return (
		<Element
			className={`card__variation${props.className ? ` ${props.className}` : ""}`}
			id={props.id}
			onClick={props.clickHandler}
		>
			<div className="card__variation__title-row">
				<h5 className="semi-bold">{props.cardTitle}</h5>
				<div className="card__variation__arrow">
					<ChevronRightIcon />
				</div>
			</div>
			{props.children}
		</Element>
	);
};

export default CardVariation;
