import { Table as SemanticUITable } from "semantic-ui-react";
import { SemanticWIDTHS } from "semantic-ui-react/dist/commonjs/generic";
import "./Table.scss";

export interface ITableHeader {
	text: string;
	columnWidth: SemanticWIDTHS;
	alignTextRight?: boolean;
	hideColumnHeaderOnMobile?: boolean;
}

export interface ITableProps {
	tableWidth: "one" | "two" | "three" | "four" | "five" | "six" | "seven" | "eight";
	id: string;
	headerText: ITableHeader[];
	cellRowText: Array<Array<string | JSX.Element>>;
	className?: string;
	key?: string;
}

const Table = (props: ITableProps): JSX.Element => {
	const { id, headerText, cellRowText, className } = props;
	const { Header, Row, HeaderCell, Body, Cell } = SemanticUITable;

	return (
		<SemanticUITable celled={true} id={id} className={`table-${props.tableWidth}-wide ${className}`}>
			<Header>
				<Row>
					{headerText.map((header: ITableHeader, index: number) => {
						const textAlignment = headerText[index].alignTextRight ? "right" : "left";
						return (
							<HeaderCell key={index} width={header.columnWidth} textAlign={textAlignment}>
								{header.text}
							</HeaderCell>
						);
					})}
				</Row>
			</Header>

			<Body>
				{cellRowText.map((rowCells: Array<string | JSX.Element>, rowIndex: number) => {
					const cells = rowCells.map((content: string | JSX.Element, columnIndex: number) => {
						const textAlignment = headerText[columnIndex].alignTextRight ? "right" : "left";
						const showHeader = !props.headerText[columnIndex].hideColumnHeaderOnMobile;
						return (
							<Cell key={`${rowIndex}-${columnIndex}`} textAlign={textAlignment}>
								{showHeader && (
									<span className="table-cell-mobile-label">{headerText[columnIndex].text}: </span>
								)}

								<span className={`table-row-content ${showHeader ? "" : "column-header-hidden"}`}>
									{content}
								</span>
							</Cell>
						);
					});
					return <Row key={rowIndex}>{cells}</Row>;
				})}
			</Body>
		</SemanticUITable>
	);
};

export default Table;
