import parse from "html-react-parser";

export const GuidesBulletList = (listStyle: "ol" | "ul", listItems: any[]): JSX.Element => {
	const Element: any = listStyle;
	return (
		<>
			<Element>
				{listItems.map((item: JSX.Element | string, index: number): JSX.Element => {
					return (
						<li key={index}>
							<span>{typeof item === "string" ? parse(item) : item}</span>
						</li>
					);
				})}
			</Element>
		</>
	);
};
