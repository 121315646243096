interface IGuideImage {
	src: any;
	alt?: string;
	style?: React.CSSProperties;
}

export const GuidesImages = (listItems: IGuideImage[]): JSX.Element => {
	return (
		<div className="guides-image-container">
			{listItems.map((item: IGuideImage, index: number): JSX.Element => {
				return <img src={item.src} alt={item.alt} style={item.style || undefined} key={index} />;
			})}
		</div>
	);
};
