import { ChevronRightIcon } from "@lifesg/react-icons/chevron-right";
import Link from "next/link";
import "./Card.scss";

interface ICardWithImageProps {
	id: string;
	header: string;
	description: string;
	image: any;
	imageAltText?: string;
	href: string;
	height: number;
	width: number;
}

const MaintenanceBanner = (props: ICardWithImageProps): JSX.Element => {
	return (
		<Link href={props.href} className="link-card--with-image">
			<div id={props.id} className="card--with-image">
				<img
					src={props.image}
					alt={props.imageAltText ? props.imageAltText : ""}
					height={props.height}
					width={props.width}
				/>
				<div className="card--with-image__header">
					<h4 className="semi-bold">{props.header}</h4>
					<ChevronRightIcon className="card--with-image__arrow" />
				</div>
				<p className="mt8 mb0">{props.description}</p>
			</div>
		</Link>
	);
};

export default MaintenanceBanner;
