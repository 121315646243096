import ExternalLinkIcon from "app/components/widgets/ExternalLinkIcon";
import { ILinkItem } from "app/utils/interfaces";
import { useGlobalLanguageState } from "app/utils/internationalization/GlobalLanguageProvider";
import { replaceInlineLinks } from "app/utils/internationalization/TranslationPageRenderer";
import { AvailableLanguages } from "app/utils/internationalization/constants";
import parse from "html-react-parser";
import { useCallback } from "react";

type TranslatedKeys = {
	[key: string]: { data: string };
};

type KVTranslationJson = {
	[lang in AvailableLanguages]: TranslatedKeys;
};

export const useKeyValueTranslation = <T extends KVTranslationJson>(
	translationsJson: T,
): {
	language: AvailableLanguages;
	getTranslatedString: (key: keyof T[AvailableLanguages.en]) => string;
	getTranslated: (key: keyof T[AvailableLanguages.en]) => JSX.Element;
	getTranslatedLinkItems: (key: keyof T[AvailableLanguages.en]) => ILinkItem[];
} => {
	const [language] = useGlobalLanguageState();

	/** Returns translated string for given key in the current language */
	const getTranslatedString = useCallback(
		(key: keyof T[AvailableLanguages.en]): string => {
			return translationsJson[language][key as string].data;
		},
		[language, translationsJson],
	);

	/** Returns translation for given key in the current language with some tags parsed (strong, links, ...) */
	const getTranslated = useCallback(
		(key: keyof T[AvailableLanguages.en]): JSX.Element => {
			const translatedString = getTranslatedString(key);
			return <>{parseTextWithLinks(translatedString)}</>;
		},
		[getTranslatedString],
	);

	/** Returns array of translated ILinkItems */
	const getTranslatedLinkItems = useCallback(
		(key: keyof T[AvailableLanguages.en]): ILinkItem[] => {
			const translatedString = getTranslatedString(key);
			return parseLinksToLinkItems(translatedString);
		},
		[getTranslatedString],
	);

	return { language, getTranslatedString, getTranslated, getTranslatedLinkItems };
};

const LINK_REGEX = () => /\[\[(?<linkText>.+?)\|(?<linkHref>.+?)]]/gm;
const EXTERNAL_LINK_REGEX = /^https?:/;

// converts marked up links into an array of `ILinkItem`s and strings
const parseLinksToLinkItems = (s: string): ILinkItem[] => {
	const regex = LINK_REGEX();
	const elems: ILinkItem[] = [];
	let match: RegExpExecArray | null;
	while ((match = regex.exec(s)) !== null && match.groups) {
		const { linkText, linkHref } = match.groups;
		if (linkText && linkHref) elems.push(createILinkItem(linkText, linkHref));
	}
	return elems;
};

const createILinkItem = (name: string, path: string): ILinkItem => {
	const linkItem: ILinkItem = { name, path };
	const isExternalLink = EXTERNAL_LINK_REGEX.test(path);
	if (isExternalLink) linkItem.iconRight = ExternalLinkIcon;
	return linkItem;
};

export const parseTextWithLinks = (text: string): string | JSX.Element | JSX.Element[] => {
	return parse(replaceInlineLinks(text, "link"));
};
