import { ICircleFillIcon } from "@lifesg/react-icons/i-circle-fill";
import * as React from "react";
import "./Blockquote.scss";

interface IProps {
	className?: string;
	fullWidth?: boolean;
	children?: React.ReactNode;
}

const Blockquote = (props: IProps): JSX.Element => {
	if (!props.children) {
		return <></>;
	}

	return (
		<div
			className={`blockquote${props.className ? ` ${props.className}` : ""} ${
				props.fullWidth ? "full-width" : ""
			}`}
		>
			<div className="blockquote__icon">
				<ICircleFillIcon className="ds-icon--size-18" />
			</div>
			<div>{props.children}</div>
		</div>
	);
};

export default Blockquote;
