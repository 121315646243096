import API from "app/api/client.contract";
import { getEvents as getEventContract } from "app/common/api/eventListing/routes";
import { IEventListingCardProps } from "app/components/composites/EventListingCard/EventListingCard";
import moment from "moment";

// =============================================================================
// Event details
// =============================================================================
const getEvents = async (params: {
	startDate?: string;
	endDate?: string;
	deliveryMode?: string;
	size?: number;
}): Promise<IEventListingCardProps[]> => {
	const { startDate, endDate, deliveryMode, size } = params;
	const q = new getEventContract.Query();
	q.startDate = startDate ? startDate : moment().utc().format();
	q.endDate = endDate ? endDate : moment().add(1, "year").utc().format();
	q.label = deliveryMode;
	q.size = size;

	const response = await API.callV2<getEventContract.Response>(getEventContract, { query: q });

	return response.map((data) => {
		const event: IEventListingCardProps = {
			eventTitle: data.eventTitle ?? "",
			eventDescription: data.eventDescription ?? "",
			startTime: moment(data.startTime),
			endTime: moment(data.endTime),
			language: data.languages?.[0] ?? "",
			location: data.locations?.[0] ?? "",
			isFullyBooked: data.availabilityCount === 0,
			bookingPageURL: decodeURIComponent(data.bookingPageURL),
		};
		return event;
	});
};

const EventDetailsAPI = {
	getEvents,
};

export default EventDetailsAPI;
