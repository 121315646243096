import "./styles.scss";

export interface ITimelineItemProps {
	content: string | JSX.Element | JSX.Element[];
}

export const TimelineItem = (item: ITimelineItemProps): JSX.Element => {
	return (
		<div className="timeline__wrapper">
			<div className="timeline__wrapper__indicator">
				<div className="timeline__wrapper__indicator__circle"></div>
				<div className="timeline__wrapper__indicator__line"></div>
			</div>
			<div className="timeline__wrapper__content">{item.content}</div>
		</div>
	);
};
