import translations from "app/assets/files/key-value-translations/homepage.json";
import BasicLink from "app/components/basic/Link";
import determineSessionStatus, {
	SessionStatusType,
} from "app/components/composites/SessionModal/determineSessionStatus";
import Segment from "app/components/page/Segment";
import { IProps } from "app/modules/common/type";
import { getCookie } from "app/utils/cookies";
import { withAuthRedirect } from "app/utils/url";
import { Grid } from "semantic-ui-react";

export const Vault = (props: IProps): JSX.Element => {
	let loggedIn = false;
	const expiry = getCookie("expiry");
	const sessionStatus = determineSessionStatus(expiry);

	if (sessionStatus === SessionStatusType.active) {
		loggedIn = true;
	}

	return (
		<Segment className="landing-page-vault-bottom-margin" background="light-blue">
			<Grid>
				<Grid.Row columns={12}>
					<Grid.Column mobile={12} tablet={12} computer={4} className="no-margin">
						<img
							className="index__image-login"
							src="/images/secured-sharing-hero.png"
							alt={translations[props.language].homepageVaultLoginImage.data}
						/>
					</Grid.Column>
					<Grid.Column mobile={12} tablet={12} computer={8} className="no-margin">
						<h4 className="index__intro-title-slim semi-bold mb0">
							{translations[props.language].homepageLegacyVault.data}
						</h4>
						<p className="index__sub-heading">
							{translations[props.language].homepageLegacyVaultDescription.data}
						</p>
						<div className="index__button-group">
							<BasicLink
								type="button-primary"
								id="login-button"
								href={withAuthRedirect("/vault")}
								name={
									loggedIn
										? translations[props.language].homepageVaultCTA.data
										: translations[props.language].homepageLoginCTA.data
								}
								disablePrefetch={true}
								fullWidthOnMobile={true}
								isAPIRoute={true}
							/>
							{!loggedIn && (
								<BasicLink
									href="/vault"
									name={translations[props.language].homepageVaultIntroCTA.data}
									id="find-out-more-button"
									disablePrefetch={true}
									fullWidthOnMobile={true}
								/>
							)}
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>
	);
};

export default Vault;
