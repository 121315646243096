import edcTranslations from "app/assets/files/key-value-translations/download-death-or-stillbirth-pg.json";
import translations from "app/assets/files/key-value-translations/homepage.json";
import PlainCard from "app/components/composites/SecureSharing/PlainCard";
import Segment from "app/components/page/Segment";
import { useFeatureConfig } from "app/hooks/useFeatureConfig";
import { useKeyValueTranslation } from "app/hooks/useKeyValueTranslation";
import { IProps } from "app/modules/common/type";
import { LpaOrAcpUrl } from "app/modules/lpa-or-acp/constants";
import { Grid } from "semantic-ui-react";

export const Tools = (props: IProps): JSX.Element => {
	const { getTranslatedString } = useKeyValueTranslation(edcTranslations);
	const { featureConfig } = useFeatureConfig();

	return (
		<Segment className="landing-page-bottom-margin">
			<Grid>
				<Grid.Row columns={12}>
					<Grid.Column mobile={12} tablet={12} computer={12} className="no-margin">
						<h4 className="index__intro-title semi-bold">
							{translations[props.language].homepageToolsServices.data}
						</h4>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={12}>
					<Grid.Column mobile={12} tablet={12} computer={6} className="index__card no-margin">
						<PlainCard
							id="tools-download-death-or-stillbirth-certificate"
							header={getTranslatedString("eDCLandingTitle")}
							description={getTranslatedString("eDCDownloadPeriodNotice")}
							href="/find-a-service/download-death-or-stillbirth-certificate"
						/>
					</Grid.Column>

					<Grid.Column mobile={12} tablet={12} computer={6} className="index__card no-margin">
						<PlainCard
							id="tools-combined-form"
							header={translations[props.language].homepageLPAACPTool.data}
							description={translations[props.language].homepageLPAACPTooDescription.data}
							href={LpaOrAcpUrl.landingPage}
						/>
					</Grid.Column>

					<Grid.Column mobile={12} tablet={12} computer={6} className="index__card no-margin">
						<PlainCard
							id="tools-find-a-service"
							header={translations[props.language].homepageFindAService.data}
							description={translations[props.language].homepageFindAServiceDescription.data}
							href="/find-a-service"
						/>
					</Grid.Column>
					{/* TODO: add translation once ready */}
					{featureConfig.FIND_A_TOOL_TOGGLE && (
						<Grid.Column mobile={12} tablet={12} computer={6} className="index__card no-margin">
							<PlainCard
								id="tools-find-a-tool"
								header="Find a tool"
								description="Find useful tools to learn more, such as how to write a will."
								href={"/find-a-tool"}
							/>
						</Grid.Column>
					)}
				</Grid.Row>
			</Grid>
		</Segment>
	);
};

export default Tools;
